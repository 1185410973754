<template>
    <v-app>
        <v-card>
            <v-card-title>
                <router-link class="ml-2 mr-2" :to="{ name:'courses-summary',params:{
                                                          lesson_planner_id:assignment.lesson_planner_id
                                                        }}">
                 <i class="fas fa-arrow-left"></i>   Back
                </router-link> Assignment
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col-12">
                        <v-text-field outlined dense v-model="assignment.title"
                                      placeholder=" Title"></v-text-field>
                        <span class="text-danger"
                              v-if="$v.assignment.title.$error"> Title is required</span>
                    </div>
                    <div class="col-12">
                        <ckeditor v-model="assignment.description"></ckeditor>
                        <span class="text-danger"
                              v-if="$v.assignment.description.$error">Description is required</span>
                    </div>

                    <div class="col-12">
                        <h4>Attachments</h4>
                        <attachment :assignment_id="assignment_id"></attachment>
                    </div>
                    <div class="col-12">
                        <h4>Marks</h4>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <span class="">Total Marks</span>
                            </div>
                            <div class="col-2">
                                <v-text-field
                                        v-model="assignment.total_marks"
                                        label="Total Marks"
                                        placeholder="Total Marks"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>
                            <div class="col-1">
                                <span class="">Pass Marks</span>
                            </div>
                            <div class="col-2">
                                <v-text-field
                                        v-model="assignment.pass_marks"
                                        label="Pass Marks"
                                        placeholder="Pass Marks"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>
                        </div>

                    </div>
                    <div class="col-12">
                        <h4>Availability</h4>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <span class="">Allow Submission From</span>

                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_submission_date"
                                        label="Enable Submissoin Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3">
                                <v-menu
                                        v-model="allow_submission_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="assignment.allow_submission_date"
                                                label="Allow Submission Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                outlined
                                                :disabled="!assignment.enable_submission_date"
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="assignment.allow_submission_date"
                                            @input="allow_submission_date = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <!--                            <div class="col-2">-->
                            <!--                                <v-text-field-->
                            <!--                                        :disabled="!assignment.enable_submission_date"-->
                            <!--                                        v-model="assignment.allow_submission_date"-->
                            <!--                                        label="Allow Submission Time"-->
                            <!--                                        outlined-->
                            <!--                                        dense-->
                            <!--                                        type="time"-->
                            <!--                                ></v-text-field>-->
                            <!--                            </div>-->
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <span class="">Due date</span>

                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_due_date"
                                        label="Enable Due Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3">
                                <v-menu
                                        v-model="due_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="assignment.due_date"
                                                label="Due Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                outlined
                                                :disabled="!assignment.enable_due_date"
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="assignment.due_date"
                                            @input="due_date = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <!--                            <div class="col-2">-->
                            <!--                                <v-text-field-->
                            <!--                                        :disabled="!assignment.enable_submission_date"-->
                            <!--                                        v-model="assignment.submission_date"-->
                            <!--                                        label="Due Time"-->
                            <!--                                        outlined-->
                            <!--                                        dense-->
                            <!--                                        type="time"-->
                            <!--                                ></v-text-field>-->
                            <!--                            </div>-->
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <span class="">Cut Off date</span>

                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_cut_off_date"
                                        label="Enable Cut Off Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3">
                                <v-menu
                                        v-model="cut_off_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="assignment.cut_off_date"
                                                label="Cut Off Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                outlined
                                                :disabled="!assignment.enable_cut_off_date"
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="assignment.cut_off_date"
                                            @input="cut_off_date = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <!--                            <div class="col-2">-->
                            <!--                                <v-text-field-->
                            <!--                                        :disabled="!assignment.enable_cut_off_date"-->
                            <!--                                        v-model="assignment.cut_off_date"-->
                            <!--                                        label="Cut Off Time"-->
                            <!--                                        outlined-->
                            <!--                                        dense-->
                            <!--                                        type="time"-->
                            <!--                                ></v-text-field>-->
                            <!--                            </div>-->
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <span class="">Grading Reminder date</span>

                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_grading_reminder"
                                        label="Grading Reminder Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3">
                                <v-menu
                                        v-model="grading_reminder_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="assignment.grading_reminder_date"
                                                label=" Grading Reminder Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                outlined
                                                :disabled="!assignment.enable_grading_reminder"
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="assignment.grading_reminder_date"
                                            @input="grading_reminder_date = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <!--                            <div class="col-2">-->
                            <!--                                <v-text-field-->
                            <!--                                        :disabled="!assignment.enable_grading_reminder"-->
                            <!--                                        v-model="assignment.grading_reminder_date"-->
                            <!--                                        label=" Grading Reminder Time"-->
                            <!--                                        outlined-->
                            <!--                                        dense-->
                            <!--                                        type="time"-->
                            <!--                                ></v-text-field>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="col-12">
                        <h4>Submission types</h4>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <span class="">Submission types</span>

                            </div>
                            <div class="col-2">
                                <v-select
                                        outlined
                                        dense
                                        v-model="assignment.submission_type"
                                        :items="submission_types"
                                        item-text="title"
                                        item-value="value"
                                        label="Submission Type"
                                >
                                </v-select>

                            </div>
                            <div class="col-3" v-if="assignment.submission_type ==='file_submission'">
                                <v-text-field
                                        v-model="assignment.max_no_upload_files"
                                        label="Maximum number of uploaded files"
                                        placeholder="Maximum number of uploaded files"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>
                            <div class="col-3"  v-if="assignment.submission_type ==='file_submission'">
                                <v-text-field
                                        v-model="assignment.max_submission_size"
                                        label="Maximum submission size"
                                        placeholder="Maximum submission size"
                                        outlined
                                        dense
                                        suffix="MB"
                                        type="number"
                                ></v-text-field>
                            </div>

                        </div>
                        <div class="row"  v-if="assignment.submission_type ==='file_submission'">
                            <div class="col-2">
                                <span class="">Accepted Files</span>

                            </div>
                            <div class="col-8">
                                <v-select
                                        :items="['pdf','word']"
                                        v-model="assignment.accepted_files"
                                        hide-selected
                                        label="Accepted Files"
                                        multiple
                                        outlined
                                        dense
                                        persistent-hint
                                        small-chips
                                >

                                </v-select>

                            </div>

                        </div>

                    </div>
                    <div class="col-12">
                        <h4>Feedback types</h4>
                    </div>
                    <div class="col-12">

                        <div class="row">
                            <div class="col-2">
                                <span class="">Feedback types</span>

                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.feedback_types"
                                        label="Feedback comments"
                                        value="feedback_comments"
                                ></v-checkbox>
                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.feedback_types"
                                        label="Annotate PDF"
                                        value="annotate_pdf"
                                ></v-checkbox>
                            </div>
                            <div class="col-3">
                                <v-checkbox
                                        v-model="assignment.feedback_types"
                                        label="Offline grading worksheet"
                                        value="offline_grading_worksheet"
                                ></v-checkbox>
                            </div>
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.feedback_types"
                                        label="Feedback files"
                                        value="feedback_files"
                                ></v-checkbox>

                            </div>

                        </div>

                    </div>
                    <div class="col-12">
                        <h4>Submission settings</h4>
                    </div>
                    <div class="col-12">

                        <div class="row">
                            <div class="col-2">
                                <span class="">Require that students accept the submission statement</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.enable_submission_statement"
                                        :label="`${assignment.enable_submission_statement ? 'Yes' : 'No'}`"
                                ></v-checkbox>
                            </div>
                            <div class="col-2">
                                <span class="">Additional attempts</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.additional_attempt"
                                        :label="`${assignment.additional_attempt ? 'Yes' : 'No'}`"
                                ></v-checkbox>

                            </div>
                            <div class="col-2" v-if="assignment.additional_attempt">
                                <v-text-field
                                        v-model="assignment.max_attempt"
                                        label="Maximum attempts"
                                        placeholder="Maximum attempts"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>


                        </div>

                    </div>
                    <div class="col-12">
                        <h4>Group submission settings</h4>
                    </div>
                    <div class="col-12">

                        <div class="row">
                            <div class="col-2">
                                <span class="">Students submit in groups</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.submit_in_groups"
                                        :label="`${assignment.submit_in_groups ? 'Yes' : 'No'}`"
                                ></v-checkbox>
                            </div>
                            <div class="col-2">
                                <span class="">Require group to make submission</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.require_group_submission"
                                        :label="`${assignment.require_group_submission ? 'Yes' : 'No'}`"
                                ></v-checkbox>

                            </div>
                        </div>

                    </div>
                    <div class="col-12">
                        <h4>Notifications</h4>
                    </div>
                    <div class="col-12">

                        <div class="row">
                            <div class="col-2">
                                <span class="">Notify graders about submissions</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.notify_submission"
                                        :label="`${assignment.notify_submission ? 'Yes' : 'No'}`"
                                ></v-checkbox>
                            </div>
                            <div class="col-2">
                                <span class="">Notify graders about late submissions</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.notify_late_submission"
                                        :label="`${assignment.notify_late_submission ? 'Yes' : 'No'}`"
                                ></v-checkbox>

                            </div>
                            <div class="col-2">
                                <span class="">Notify students</span>
                            </div>
                            <div class="col-1">
                                <v-checkbox
                                        v-model="assignment.notify_student_submission"
                                        :label="`${assignment.notify_student_submission ? 'Yes' : 'No'}`"
                                ></v-checkbox>

                            </div>
                        </div>

                    </div>


                    <div class="col-12">
                        <v-switch
                                v-model="assignment.is_active"
                                label="Status"
                        >
                        </v-switch>
                    </div>

                    <div class="col-12 text-right mt-4">
                        <v-btn
                                class="btn btn-standard"
                                depressed
                                @click="resetForm"
                        >Cancel
                        </v-btn>
                        <v-btn
                                class="text-white ml-2 btn btn-primary"
                                depressed
                                @click="createAndUpdate"
                                :loading="isBusy"
                        >Save & Continue
                        </v-btn>
                        <v-btn
                                class="text-white ml-2 btn btn-primary"
                                depressed
                                @click="createAndUpdateReturn"
                                :loading="isBusy"
                        >Save & Return
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import Attachment from "./Attachment";
    export default {
        name: "NewAssignment",
        mixins: [AssignmentMixin],
        components:{Attachment},
        validations: {
            assignment: {
                title: {required},
                description: {required},
            }
        },
        data() {
            return {
                dialog: false,
                menu: false,
                menu2: false,
                allow_submission_date: false,
                due_date: false,
                cut_off_date: false,
                grading_reminder_date: false,
                isBusy: false,
                edit: false,
                types: [
                    {
                        title: 'File Submission',
                        value: 'file_submission'
                    },
                    {
                        title: 'Questions',
                        value: 'questions'
                    },
                    {
                        title: 'Quiz',
                        value: 'quiz'
                    }
                ],
                submission_types: [
                    {
                        title: 'Online Text',
                        value: 'online_text'
                    },
                    {
                        title: 'File Submission',
                        value: 'file_submission'
                    },
                    {
                        title: 'Individual Questions',
                        value: 'individual_questions'
                    },


                ]

            };
        },
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },

        },
        methods: {},
        mounted() {
            this.getSingleAssignment(this.assignment_id);
        }

    }
</script>

<style scoped>

</style>